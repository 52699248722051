<template>
  <div id="app">
    <header>
      <h1>Suno Open AI Music Generator</h1>
    </header>
    <main>
      <div class="input-container">
        <input type="text" v-model="musicTitle" placeholder="Enter a prompt for the music">
        <button @click="handleGenerateMusic" :disabled="loading">生成音乐</button>
      </div>
      
      <div v-if="loading" class="loading">
        Music is being generated for you, please wait...
      </div>

      <div v-if="musicGenerated" class="music-container">
        <div v-for="music in generatedMusic" :key="music.id" class="music-item">
          <h2>{{ music.title }}</h2>
          <img :src="music.image_url" alt="Music Image">
          <p class="lyric">{{ music.lyric }}</p>
          <audio controls class="audio" @play="stopOtherMedia($event)">
            <source :src="music.audio_url" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
          <video controls class="video" @play="stopOtherMedia($event)">
            <source :src="music.video_url" type="video/mp4">
            Your browser does not support the video element.
          </video>
        </div>
      </div>

      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      musicTitle: '',
      musicGenerated: false,
      generatedMusic: [],
      loading: false,
      currentPlayingMedia: null,
      showModal: false,
      modalMessage: ''
    };
  },
  mounted() {
    document.title = "XiaoZhi AI Music Generator";
  },
  methods: {
    handleGenerateMusic() {
      if (!this.musicTitle) {
        this.showModalMessage('请输入生成音乐的提示语');
        return;
      }
      this.generateMusic();
    },
    generateMusic() {
      this.loading = true;
      this.musicGenerated = false;
      axios.post('http://120.26.169.57/api/test/generate-music', { prompt: this.musicTitle,isCustom:false,make_instrumental:false,wait_audio:true })
        .then(response => {
          this.loading = false;
          this.musicGenerated = true;
          this.generatedMusic = response.data.data;
        })
        .catch(error => {
          this.loading = false;
          console.error('Error generating music:', error);
        });
    },
    stopOtherMedia(event) {
      if (this.currentPlayingMedia && this.currentPlayingMedia !== event.target) {
        this.currentPlayingMedia.pause();
        this.currentPlayingMedia.currentTime = 0;
      }
      this.currentPlayingMedia = event.target;
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

header {
  background-color: #42b983;
  padding: 20px;
  color: white;
}

main {
  margin: 20px;
  max-width: 80%;
  margin: 20px auto;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 7px;
  margin-right: 10px;
  font-size: 1em;
  flex: 1;
  max-width: 600px;
}

button {
  padding: 8px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 4px;
}

button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

.loading {
  font-size: 1.2em;
  color: #42b983;
  margin-top: 20px;
}

.music-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.music-item {
  flex: 1;
  min-width: 300px;
  max-width: 45%;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: left;
}

.lyric {
  font-size: 1.2em;
  margin: 10px 0;
  white-space: pre-line;
}

.audio {
  width: 100%;
  margin-top: 10px;
}

.video {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
}

@media (max-width: 600px) {
  .input-container {
    flex-direction: column;
  }
  
  input[type="text"] {
    margin-right: 0;
    margin-bottom: 10px;
    max-width: 100%;     
    
  }

  .music-item {
    max-width: 100%;
  }
}

@media (min-width: 601px) {
  .video {
    width: 100%;
    margin: 10px auto;
  }
}
</style>
